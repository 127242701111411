$(document).ready(function() {

    var imgRatio = {
        black_pearl_film_1 : "landscape",
        black_pearl_film_2 : "landscape",
        black_pearl_film_3 : "portrait",
        black_pearl_film_4 : "portrait",
        black_pearl_film_5 : "landscape",
        black_pearl_film_6 : "landscape",
        black_pearl_film_7 : "portrait",
        black_pearl_film_8 : "landscape",
        black_pearl_film_9 : "landscape",
        black_pearl_film_10 : "portrait",
        black_pearl_film_11 : "portrait",
        black_pearl_film_12 : "landscape",
        black_pearl_film_13 : "portrait",
        black_pearl_film_14 : "landscape",
        black_pearl_film_15 : "landscape",
        black_pearl_film_16 : "landscape",
        black_pearl_film_17 : "landscape",
        black_pearl_film_18 : "landscape",
        black_pearl_film_19 : "landscape",
        black_pearl_film_20 : "landscape",
        bodega_1 : "landscape",
        bodega_2 : "landscape",
        bodega_3 : "landscape",
        bodega_4 : "portrait",
        bodega_5 : "portrait",
        bodega_6 : "portrait",
        bodega_7 : "landscape",
        bodega_8 : "square",
        bodega_9 : "square",
        bodega_10 : "portrait",
        bodega_11 : "landscape",
        bodega_12 : "landscape",
        bodega_13 : "portrait",
        bodega_14 : "landscape",
        bodega_15 : "landscape",
        bodega_16 : "landscape",
        bodega_17 : "landscape",
        bodega_18 : "landscape",
        bodega_19 : "landscape",
        bodega_20 : "landscape",
        burger_king_1 : "square",
        burger_king_2 : "portrait",
        burger_king_3 : "portrait",
        burger_king_4 : "square",
        burger_king_5 : "square",
        burger_king_6 : "square",
        burger_king_7 : "square",
        burger_king_8 :"square",
        burger_king_9 : "landscape",
        burger_king_10 : "portrait",
        burger_king_11 : "square",
        burger_king_12 : "square",
        burger_king_13 : "square",
        burger_king_14 : "portrait",
        burger_king_15 : "landscape",
        burger_king_16 : "square",
        burger_king_17 : "square",
        burger_king_18 : "square",
        burger_king_19 : "portrait",
        burger_king_20 : "landscape",
        carfax_1 : "landscape",
        carfax_2 : "landscape",
        carfax_3 : "landscape",
        carfax_4 : "landscape",
        carfax_5 : "landscape",
        carfax_6 : "landscape",
        carfax_7 : "landscape",
        carfax_8 : "landscape",
        carfax_9 : "portrait",
        carfax_10 : "landscape",
        carfax_11 : "landscape",
        carfax_12 : "landscape",
        carfax_13 : "landscape",
        carfax_14 : "landscape",
        carfax_15 : "landscape",
        carfax_16 : "landscape",
        carfax_17 : "landscape",
        carfax_18 : "landscape",
        carfax_19 : "landscape",
        carfax_20 : "landscape",
        citroen_1 : "square",
        citroen_2 : "square",
        citroen_3 : "square",
        citroen_4 : "square",
        citroen_5 : "portrait",
        citroen_6 : "landscape",
        citroen_7 : "landscape",
        citroen_8 : "landscape",
        citroen_9 : "portrait",
        citroen_10 : "landscape",
        citroen_11 : "square",
        citroen_12 : "landscape",
        citroen_13 : "landscape",
        citroen_14 : "landscape",
        citroen_15 : "square",
        citroen_16 : "square",
        citroen_17 : "landscape",
        citroen_18 : "landscape",
        citroen_19 : "landscape",
        citroen_20 : "portrait",
        clubfeet_1 : "landscape",
        clubfeet_2 : "landscape",
        clubfeet_3 : "square",
        clubfeet_4 : "square",
        clubfeet_5 : "square",
        clubfeet_6 : "landscape",
        clubfeet_7 : "square",
        clubfeet_8 : "square",
        clubfeet_9 : "square",
        clubfeet_10 : "portrait",
        clubfeet_11 : "landscape",
        cydr_1 : "square",
        cydr_2 : "portrait",
        cydr_3 : "square",
        cydr_4 : "square",
        cydr_5 : "landscape",
        cydr_6 : "landscape",
        cydr_7 : "square",
        cydr_8 : "landscape",
        cydr_9 : "landscape",
        cydr_10 : "landscape",
        cydr_11 : "square",
        cydr_12 : "landscape",
        cydr_13 : "square",
        cydr_14 : "square",
        edgar_1 : "landscape",
        edgar_2 : "square",
        edgar_3 : "landscape",
        edgar_4 : "landscape",
        edgar_5 : "square",
        edgar_6 : "square",
        edgar_7 : "square",
        edgar_8 : "square",
        edgar_9 : "square",
        edgar_10 : "landscape",
        edgar_11 : "landscape",
        edgar_12 : "landscape",
        edgar_13 : "landscape",
        edgar_14 : "square",
        edgar_15 : "landscape",
        edgar_16 : "square",
        edgar_17 : "square",
        edgar_18 : "landscape",
        edgar_19 : "square",
        edgar_20 : "square",
        edgars_1 : "square",
        edgars_2 : "square",
        edgars_3 : "square",
        edgars_4 : "square",
        edgars_5 : "square",
        edgars_6 : "landscape",
        edgars_7 : "portrait",
        edgars_8 : "portrait",
        edgars_9 : "landscape",
        edgars_10 : "landscape",
        edgars_11 : "square",
        edgars_12 : "portrait",
        edgars_13 : "square",
        edgars_14 : "square",
        edgars_15 : "landscape",
        edgars_16 : "landscape",
        edgars_17 : "landscape",
        edgars_18 : "landscape",
        edgars_19 : "square",
        edgars_2_1 : "landscape",
        edgars_2_2 : "landscape",
        edgars_2_3 : "landscape",
        edgars_2_4 : "landscape",
        edgars_2_5 : "landscape",
        edgars_2_6 : "landscape",
        edgars_2_7 : "landscape",
        edgars_2_8 : "landscape",
        edgars_2_9 : "landscape",
        edgars_2_10 : "landscape",
        edgars_2_11 : "landscape",
        edgars_2_12 : "landscape",
        edgars_2_13 : "landscape",
        edgars_2_14 : "landscape",
        edgars_2_15 : "landscape",
        edgars_2_16 : "landscape",
        edgars_2_17 : "landscape",
        edgars_2_18 : "landscape",
        edgars_2_19 : "landscape",
        edgars_2_20 : "landscape",
        gazelle_1 : "landscape",
        gazelle_2 : "square",
        gazelle_3 : "square",
        gazelle_4 : "landscape",
        gazelle_5 : "landscape",
        gazelle_6 : "square",
        gazelle_7 : "square",
        gazelle_8 : "landscape",
        gazelle_9 : "square",
        gazelle_10 : "landscape",
        gazelle_11 : "square",
        gazelle_12 : "square",
        gazelle_13 : "square",
        gazelle_14 : "square",
        gerolsteiner_1 : "landscape",
        gerolsteiner_2 : "landscape",
        gerolsteiner_3 : "square",
        gerolsteiner_4 : "landscape",
        gerolsteiner_5 : "square",
        gerolsteiner_6 : "landscape",
        gerolsteiner_7 : "landscape",
        gerolsteiner_8 : "square",
        gerolsteiner_9 : "landscape",
        gerolsteiner_10 : "landscape",
        gerolsteiner_11 : "square",
        gerolsteiner_12 : "landscape",
        gerolsteiner_13 : "portrait",
        gerolsteiner_14 : "square",
        gerolsteiner_15 : "portrait",
        gerolsteiner_16 : "landscape",
        gerolsteiner_17 : "square",
        gerolsteiner_18 : "portrait",
        gerolsteiner_19 : "landscape",
        gerolsteiner_20 : "square",
        jack_parow_1 : "square",
        jack_parow_2 : "landscape",
        jack_parow_3 : "landscape",
        jack_parow_4 : "portrait",
        jack_parow_5 : "landscape",
        jack_parow_6 : "square",
        jack_parow_7 : "landscape",
        jack_parow_8 : "square",
        jack_parow_9 : "landscape",
        jack_parow_10 : "square",
        jack_parow_11 : "landscape",
        jack_parow_12 : "landscape",
        jack_parow_13 : "portrait",
        jack_parow_14 : "square",
        jack_parow_15 : "landscape",
        jack_parow_16 : "square",
        jack_parow_17 : "landscape",
        jack_parow_18 : "landscape",
        jack_parow_19 : "portrait",
        kalahari_millionth_1 : "landscape",
        kalahari_millionth_2 : "landscape",
        kalahari_millionth_3 : "landscape",
        kalahari_millionth_4 : "landscape",
        kalahari_millionth_5 : "landscape",
        kalahari_millionth_6 : "square",
        kalahari_millionth_7 : "landscape",
        kalahari_millionth_8 : "landscape",
        kalahari_millionth_9 : "square",
        kalahari_millionth_10 : "landscape",
        kalahari_millionth_11 : "square",
        kalahari_millionth_12 : "landscape",
        kalahari_millionth_13 : "landscape",
        kalahari_millionth_14 : "landscape",
        kalahari_millionth_15 : "landscape",
        kalahari_millionth_16 : "square",
        kalahari_millionth_17 : "landscape",
        kalahari_millionth_18 : "square",
        kalahari_millionth_19 : "square",
        kalahari_millionth_20 : "landscape",
        kalahari_widest_1 : "landscape",
        kalahari_widest_2 : "landscape",
        kalahari_widest_3 : "landscape",
        kalahari_widest_4 : "landscape",
        kalahari_widest_5 : "landscape",
        kalahari_widest_6 : "landscape",
        kalahari_widest_7 : "landscape",
        kalahari_widest_8 : "square",
        kalahari_widest_9 : "square",
        kalahari_widest_10 : "landscape",
        kalahari_widest_11 : "square",
        kalahari_widest_12 : "landscape",
        kalahari_widest_13 : "square",
        kalahari_widest_14 : "landscape",
        kalahari_widest_15 : "landscape",
        kalahari_widest_16 : "square",
        kalahari_widest_17 : "square",
        kalahari_widest_18 : "landscape",
        kalahari_widest_19 : "square",
        kalahari_widest_20 : "landscape",
        lil_lets_1 : "landscape",
        lil_lets_2 : "landscape",
        lil_lets_3 : "landscape",
        lil_lets_4 : "landscape",
        lil_lets_5 : "landscape",
        lil_lets_6 : "landscape",
        lil_lets_7 : "landscape",
        lil_lets_8 : "landscape",
        lil_lets_9 : "landscape",
        lil_lets_10 : "landscape",
        lil_lets_11 : "landscape",
        lil_lets_12 : "landscape",
        lil_lets_13 : "landscape",
        lil_lets_14 : "landscape",
        lil_lets_15 : "landscape",
        lil_lets_16 : "landscape",
        lil_lets_17 : "landscape",
        lil_lets_18 : "landscape",
        lil_lets_19 : "landscape",
        lil_lets_20 : "landscape",
        lindemans_1 : "square",
        lindemans_2 : "square",
        lindemans_3 : "square",
        lindemans_4 : "square",
        lindemans_5 : "square",
        lindemans_6 : "square",
        lindemans_7 : "square",
        lindemans_8 : "square",
        lindemans_9 : "square ",
        lindemans_10 : "square",
        lindemans_11 : "square",
        lindemans_12 : "square",
        lindemans_13 : "square",
        lindemans_14 : "square",
        lindemans_15 : "square",
        lindemans_16 : "square",
        lindemans_17 : "square",
        lindemans_18 : "square",
        lindemans_19 : "square",
        lindemans_20 : "square",
        lindemans_2_1 : "square",
        lindemans_2_2 : "square",
        lindemans_2_3 : "square",
        lindemans_2_4 : "square",
        lindemans_2_5 : "square",
        lindemans_2_6 : "square",
        lindemans_2_7 : "square",
        lindemans_2_8 : "square",
        lindemans_2_9 : "square ",
        lindemans_2_10 : "square",
        lindemans_2_11 : "square",
        lindemans_2_12 : "square",
        lindemans_2_13 : "square",
        lindemans_2_14 : "square",
        lindemans_2_15 : "square",
        lindemans_2_16 : "square",
        lindemans_2_17 : "square",
        lindemans_2_18 : "square",
        lindemans_2_19 : "square",
        lindemans_2_20 : "square",
        pathfinder_havas_1 : "square",
        pathfinder_havas_2 : "square",
        pathfinder_havas_3 : "square",
        pathfinder_havas_4 : "square",
        pathfinder_havas_5 : "square",
        pathfinder_havas_6 : "square",
        pathfinder_havas_7 : "square",
        pathfinder_havas_8 : "square",
        pathfinder_havas_9 : "square ",
        pathfinder_havas_10 : "square",
        pathfinder_havas_11 : "square",
        pathfinder_havas_12 : "square",
        pathfinder_havas_13 : "square",
        pathfinder_havas_14 : "square",
        pathfinder_havas_15 : "square",
        pathfinder_havas_16 : "square",
        pathfinder_havas_17 : "square",
        pathfinder_havas_18 : "square",
        pathfinder_havas_19 : "square",
        pathfinder_havas_20 : "square",
        sinclair_1 : "square",
        sinclair_2 : "landscape",
        sinclair_3 : "landscape",
        sinclair_4 : "square",
        sinclair_5 : "landscape",
        sinclair_6 : "square",
        sinclair_7 : "landscape",
        sinclair_8 : "square",
        sinclair_9 : "portrait",
        sinclair_10 : "square",
        sinclair_11 : "square",
        sinclair_12 : "landscape",
        sinclair_13 : "portrait",
        sinclair_14 : "square",
        sinclair_15 : "landscape",
        sinclair_16 : "landscape",
        sinclair_17 : "landscape",
        sinclair_18 : "portrait",
        sinclair_19 : "landscape",
        sinclair_20 : "square",
        sportscheck_1 : "portrait",
        sportscheck_2 : "square",
        sportscheck_3 : "square",
        sportscheck_4 : "landscape",
        sportscheck_5 : "square",
        sportscheck_6 : "square",
        sportscheck_7 : "landscape",
        sportscheck_8 : "landscape",
        sportscheck_9 : "square",
        sportscheck_10 : "landscape",
        sportscheck_11 : "landscape",
        sportscheck_12 : "landscape",
        sportscheck_13 : "landscape",
        sportscheck_14 : "landscape",
        sportscheck_15 : "landscape",
        sportscheck_16 : "square",
        sportscheck_17 : "square",
        sportscheck_18 : "landscape",
        sportscheck_19 : "landscape",
        sportscheck_20 : "square",
        toaster_strudel_1 : "landscape",
        toaster_strudel_2 : "landscape",
        toaster_strudel_3 : "square",
        toaster_strudel_4 : "square",
        toaster_strudel_5 : "landscape",
        toaster_strudel_6 : "square",
        toaster_strudel_7 : "landscape",
        toaster_strudel_8 : "square",
        toaster_strudel_9 : "landscape",
        toaster_strudel_10 : "landscape",
        toaster_strudel_11 : "landscape",
        toaster_strudel_12 : "landscape",
        toaster_strudel_13 : "landscape",
        toaster_strudel_14 : "portrait",
        toaster_strudel_15 : "square",
        toaster_strudel_16 : "square",
        toaster_strudel_17 : "square",
        toaster_strudel_18 : "portrait",
        toaster_strudel_19 : "landscape",
        toaster_strudel_20 : "landscape",
        toro_1 : "square",
        toro_2 : "landscape",
        toro_3 : "square",
        toro_4 : "square",
        toro_5 : "landscape",
        toro_6 : "portrait",
        toro_7 : "square",
        toro_8 : "landscape",
        toro_9 : "landscape",
        toro_10 : "square",
        toro_11 : "landscape",
        toro_12 : "landscape",
        toro_13 : "square",
        toro_14 : "landscape",
        toro_15 : "portrait",
        toro_16 : "square",
        toro_17 : "square",
        toro_18 : "square",
        toro_19 : "landscape",
        toro_20 : "portrait",
        van_coke_kartel_1 : "landscape",
        van_coke_kartel_2 : "square",
        van_coke_kartel_3 : "landscape",
        van_coke_kartel_4 : "square",
        van_coke_kartel_5 : "square",
        van_coke_kartel_6 : "square",
        van_coke_kartel_7 : "square",
        van_coke_kartel_8 : "landscape",
        van_coke_kartel_9 : "landscape",
        van_coke_kartel_10 : "square",
        van_coke_kartel_11 : "square",
        van_coke_kartel_12 : "landscape",
        zubr_1 : "portrait",
        zubr_2 : "landscape",
        zubr_3 : "landscape",
        zubr_4 : "square",
        zubr_5 : "square",
        zubr_6 : "landscape",
        zubr_7 : "square",
        zubr_8 : "landscape",
        zubr_9 : "landscape",
        zubr_10 : "portrait",
        zubr_11 : "landscape",
        zubr_12 : "landscape",
        zubr_13 : "square",
        zubr_14 : "portrait",
        zubr_15 : "landscape",
        zubr_16 : "square",
        zubr_17 : "portrait",
        zubr_18 : "landscape",
        zubr_19 : "landscape",
        zubr_20 : "square"
    };

    var img = $(".slideShowImg img");

    function checkImgRatio(thisObj) {
        var imgSrc = img.attr('src');
        var imgSrcArray = imgSrc.split('/');
        var folderPosition = imgSrcArray.length - 2;
        var imgPosition = imgSrcArray.length - 1;
        var folderName = imgSrcArray[folderPosition];
        var imgName = imgSrcArray[imgPosition].replace('.jpg', '');
        var imgVar = folderName + "_" + imgName;
        var imgStyle = imgRatio[imgVar] + "-img";
        img.removeClass().addClass(imgStyle);
        /*
         * create an offscreen image that isn't scaled
         * but contains the same image.
         * Because it's cached it should be instantly here.
         */
        //var offScreenImg = new Image();
        //offScreenImg.src = img.attr('src');
        // you should check here if the image has finished loading
        // this can be done with theImage.complete

        /*var viewPortWidth = $( window ).width();
         var viewPortHeight = $( window ).height();
         var imgHeight = offScreenImg.height;
         var imgWidth = offScreenImg.width;

         if ( imgHeight > imgWidth ) {
         var newImgHeight = parseInt(viewPortHeight * 0.8);
         var scaleRatio = imgHeight/newImgHeight;
         img.height(newImgHeight);
         img.width(imgWidth/scaleRatio);
         } else if ( imgHeight < imgWidth ) {
         var newImgWidth = parseInt(viewPortWidth * 0.5);
         var scaleRatio = imgWidth/newImgWidth;
         img.width(newImgWidth);
         img.height(imgHeight/scaleRatio);
         } else if ( imgHeight == imgWidth ) {
         var newImgWidth = parseInt(viewPortWidth * 0.4);
         img.width(newImgWidth);
         img.height(newImgWidth);
         }*/
    }

    var totalImages = parseInt( $('.countImages').text() );

    function nextLoad() {
        var currentImg = parseInt(img.attr('data-count'), 10);
        var nextImg = currentImg + 1;
        var countImages = totalImages;
        if (nextImg <= countImages) {
            var nextImgSrc = img.prop('src').replace(currentImg + '.jpg', nextImg + '.jpg');
            img.attr('data-count', nextImg);
            img.prop('src', nextImgSrc);
        } else {
            var nextImgSrc = img.prop('src').replace(currentImg + '.jpg', '1.jpg');
            img.attr('data-count', 1);
            img.prop('src', nextImgSrc);
        }
    }

    function previousLoad() {
        var currentImg = parseInt(img.attr('data-count'));
        var prevImg = currentImg - 1;
        var countImages = totalImages;
        if (prevImg === 0) {
            var prevImgSrc = img.prop('src').replace(currentImg + '.jpg', countImages + '.jpg');
            img.prop('src', prevImgSrc);
        } else if (prevImg <= countImages) {
            var prevImgSrc = img.prop('src').replace(currentImg + '.jpg', prevImg + '.jpg');
            img.attr('data-count', prevImg);
            img.prop('src', prevImgSrc);
        }
    }

    $('.btnNextImg').on("click", function() {
        nextLoad();
        // img.animate({
        //     opacity: 0,
        // }, 1, function() {
        //     // Animation complete.
        // });
        // setTimeout(nextLoad, 10);
        // setTimeout(checkImgRatio, 100);
    });

    $('.btnPreviousImg').on("click", function() {
        previousLoad();
    });

    // Updated with actual migration data
    function nextLoadUpdated() {
        var currentImg = parseInt(img.attr('data-count'));
        var nextImg = currentImg + 1;
        var countImages = $('.albumImage').length;
        var nextImgSrc = '';
        if (nextImg <= countImages) {
            nextImgSrc = $("input[name='album[" + nextImg + "]']").val();
            img.attr('data-count', nextImg);
            img.prop('src', nextImgSrc);
        } else {
            nextImgSrc = $("input[name='album[1]']").val();
            img.attr('data-count', 1);
            img.prop('src', nextImgSrc);
        }
    }

    function previousLoadUpdated() {
        var currentImg = parseInt(img.attr('data-count'));
        var prevImg = currentImg - 1;
        var countImages = $('.albumImage').length;
        var prevImgSrc = '';
        if (prevImg === 0) {
            prevImgSrc = $("input[name='album[" + countImages + "]']").val();
            img.attr('data-count', countImages);
            img.prop('src', prevImgSrc);
        } else if (prevImg <= countImages) {
            prevImgSrc = $("input[name='album[" + prevImg + "]']").val();
            img.attr('data-count', prevImg);
            img.prop('src', prevImgSrc);
        }
    }

    $('.btnNextImgUpdated').on("click", function() {
        nextLoadUpdated();
    });

    $('.btnPreviousImgUpdated').on("click", function() {
        previousLoadUpdated();
    });
});